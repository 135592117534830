import React, { Component } from 'react';

class Index extends Component {
  render() {
    return (
      <p className="alert alert-secondary">
        Welcome to Bayes-CMS. To get started <b>select a network above</b> and
        then click one of the buttons to navigate.
      </p>
    );
  }
}
export default Index;
