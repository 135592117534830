import { FETCH_NETWORKS } from '../actions/networks';

const intitialState = {};

const networks = (state = intitialState, action) => {
  switch (action.type) {
    case FETCH_NETWORKS:
      return action.payload;
    default:
      return state;
  }
};

export default networks;
