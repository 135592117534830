import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, Dropdown } from 'semantic-ui-react';
import '../styles/navigation.css';

const { bool, func, shape, instanceOf, string } = PropTypes;

class Navigation extends Component {
  render() {
    const props = this.props;

    return (
      <Menu size="tiny" className="main-navigation">
        <Menu.Item>
          <Menu.Header>
            <div className="alert alert-info" style={{ marginBottom: 0 }}>
              <b>Bayes-CMS</b>
            </div>
          </Menu.Header>
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            onChange={props.changeNetworkRoute}
            className="network-dropdown"
            placeholder="Select Network"
            selectOnNavigation={false}
            fluid
            search
            selection
            options={props.dropdownOptions}
            value={props.selectedNetwork}
          />
        </Menu.Item>
        <Menu.Item onClick={props.onClickViewNetwork}>Visualize</Menu.Item>
        <Menu.Item onClick={props.onClickEvaluateNetwork}>Evaluate</Menu.Item>
        <Menu.Item onClick={props.onClickConstructNetwork}>Construct</Menu.Item>
        <Menu.Item onClick={props.onClickSimulateNetwork}>Simulate</Menu.Item>
        <Menu.Item>
          <Dropdown
            item
            text={
              props.networkExportRunning === true ? (
                <span>
                  <Icon loading name="spinner" /> Export
                </span>
              ) : (
                <span>Export</span>
              )
            }
          >
            <Dropdown.Menu>
              <Dropdown.Header>Choose format</Dropdown.Header>
              <Dropdown.Item onClick={props.onClickExportNetworkXML}>
                XML
              </Dropdown.Item>
              <Dropdown.Item onClick={props.onClickExportNetworkCSV}>
                CSV
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item className="user-menu">
            <p className="user-name">
              <img
                src={props.user.imageUrl}
                className="rounded-circle"
                style={{ width: 30, height: 30 }}
              />{' '}
              {props.user.name}
            </p>
            <a href="" className="user-sign-out" onClick={props.unAuthUser}>
              Sign Out
              <Icon name="log out" className="sign-out-icon" />
            </a>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}

Navigation.defaultProps = {
  user: {},
  dropdownOptions: [],
};

Navigation.propTypes = {
  changeNetworkRoute: func.isRequired,
  onClickSimulateNetwork: func.isRequired,
  onClickConstructNetwork: func.isRequired,
  onClickViewNetwork: func.isRequired,
  onClickEvaluateNetwork: func.isRequired,
  onClickExportNetworkXML: func.isRequired,
  onClickExportNetworkCSV: func.isRequired,
  networkExportRunning: bool.isRequired,
  unAuthUser: func.isRequired,
  selectedNetwork: string,
  user: shape({
    isAuthed: bool.isRequired,
  }),
  dropdownOptions: instanceOf(Array),
};

export default Navigation;
