import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Navigation from '../components/navigation';
import { unAuthUser } from '../actions/user';
import { exportNetworkFromAPI } from '../services/networks';

import 'react-notifications/lib/notifications.css';

const { shape, func, instanceOf } = PropTypes;
const msgNetworkNotSelected = 'First select a network';

class NavigationContainer extends Component {
  static propTypes = {
    dispatch: func.isRequired,
    getNetworks: func.isRequired,
    history: instanceOf(Object).isRequired,
    user: shape({}).isRequired,
    networks: shape({}).isRequired,
  };

  constructor(props) {
    super(props);
    let selectedNetwork = window.location.pathname.substr(1).split('/')[1];
    if (typeof selectedNetwork === 'undefined' || selectedNetwork === '/') {
      selectedNetwork = null;
    }
    this.state = {
      selectedNetwork: selectedNetwork,
      networks: {},
      networkExportRunning: false,
    };
    this.unAuthUser = this.unAuthUser.bind(this);
    this.onClickViewNetwork = this.onClickViewNetwork.bind(this);
    this.onClickSimulateNetwork = this.onClickSimulateNetwork.bind(this);
    this.onClickConstructNetwork = this.onClickConstructNetwork.bind(this);
    this.onClickEvaluateNetwork = this.onClickEvaluateNetwork.bind(this);
    this.onClickExportNetwork = this.onClickExportNetwork.bind(this);
    this.onClickExportNetworkXML = this.onClickExportNetworkXML.bind(this);
    this.onClickExportNetworkCSV = this.onClickExportNetworkCSV.bind(this);
    this.changeNetworkRoute = this.changeNetworkRoute.bind(this);
  }

  async componentDidMount() {
    let networks = await this.props.getNetworks();
    this.setState({ networks: networks });
  }

  unAuthUser() {
    this.props.dispatch(unAuthUser());
  }

  changeNetworkRoute(e, data) {
    this.setState({ selectedNetwork: data.value });
  }

  onClickViewNetwork() {
    if (this.networkIsSelected() === false) {
      alert(msgNetworkNotSelected);
    } else {
      this.props.history.replace(`/network/${this.state.selectedNetwork}`);
    }
  }

  onClickEvaluateNetwork() {
    if (this.networkIsSelected() === false) {
      alert(msgNetworkNotSelected);
    } else {
      this.props.history.replace(
        `/evaluate-network/${this.state.selectedNetwork}`,
      );
    }
  }

  onClickConstructNetwork() {
    this.props.history.replace(`/build-network/`);
  }

  onClickSimulateNetwork() {
    this.props.history.replace(`/simulate-network/`);
  }

  async onClickExportNetworkXML() {
    return this.onClickExportNetwork('xml');
  }

  async onClickExportNetworkCSV() {
    return this.onClickExportNetwork('csv');
  }

  async onClickExportNetwork(fileFormat) {
    if (this.networkIsSelected() === false) {
      alert(msgNetworkNotSelected);
    } else {
      this.setState({
        ...this.state,
        networkExportRunning: true,
      });
      try {
        let response = await exportNetworkFromAPI(
          this.state.selectedNetwork,
          fileFormat,
        );
        if (response.status === 200) {
          this.setState({
            ...this.state,
            networkExportRunning: false,
          });
          const filename = response.headers['content-disposition']
            .split('filename=')[1]
            .trim()
            .slice(1, -1);
          let data = new Blob([response.data], {
            type: response.headers['content-type'],
          });

          const url = window.URL.createObjectURL(data);
          let tempLink = document.createElement('a');
          tempLink.href = url;
          tempLink.setAttribute('download', filename);
          tempLink.target = '_blank';
          tempLink.click();
          tempLink.remove();
        } else {
          this.setState({
            ...this.state,
            networkExportRunning: false,
          });
        }
      } catch (err) {
        this.setState({
          ...this.state,
          networkExportRunning: false,
        });
      }
    }
  }

  networkIsSelected() {
    if (!this.state || this.state.selectedNetwork === null) {
      return false;
    } else {
      return true;
    }
  }

  formatNetworksToOptions() {
    const networkKeys = Object.keys(this.state.networks);
    const dropdownOptions = [];
    networkKeys.map(networkID =>
      dropdownOptions.push({
        key: networkID,
        value: networkID,
        text: networkID,
      }),
    );
    return dropdownOptions;
  }

  render() {
    return (
      <Navigation
        user={this.props.user}
        dropdownOptions={this.formatNetworksToOptions()}
        changeNetworkRoute={this.changeNetworkRoute}
        onClickViewNetwork={this.onClickViewNetwork}
        onClickEvaluateNetwork={this.onClickEvaluateNetwork}
        onClickConstructNetwork={this.onClickConstructNetwork}
        onClickSimulateNetwork={this.onClickSimulateNetwork}
        onClickExportNetwork={this.onClickExportNetwork}
        onClickExportNetworkCSV={this.onClickExportNetworkCSV}
        onClickExportNetworkXML={this.onClickExportNetworkXML}
        unAuthUser={this.unAuthUser}
        selectedNetwork={this.state.selectedNetwork}
        networkExportRunning={this.state.networkExportRunning}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  networks: state.networks,
});

export default withRouter(connect(mapStateToProps)(NavigationContainer));
