import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Icon } from 'semantic-ui-react';

import store, { persistor } from './store';
import MainContainer from './containers/main-container';

const App = () => (
  <Provider store={store}>
    <PersistGate
      loading={<Icon loading name="spinner" />}
      persistor={persistor}
    >
      <MainContainer store={store} />
    </PersistGate>
  </Provider>
);

export default App;
